import React, { Component } from "react";
class Downloads extends Component {
  state = {};
  render() {
    return (
      <div>
        <p className="p-32"></p>
        <h1 className="text-8xl text-white">Downloads Page</h1>
      </div>
    );
  }
}

export default Downloads;
